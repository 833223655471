import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { IconButton, Stack, Typography, useTheme } from '@mui/material';
import FieldDisplay from 'components/commons/FieldDisplay/FieldDisplay';
import { useFetchHistorizedBillInfosQuery } from 'generated/graphql';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'store';
import { displayEmployee } from 'utils';

interface MissionSummaryViewProps {
  setIsModalContentLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setMissionModalStep: React.Dispatch<React.SetStateAction<number>>;
  setMissionModalVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function MissionSummaryView({
  setIsModalContentLoading,
  setMissionModalStep,
  setMissionModalVisibility,
}: MissionSummaryViewProps) {
  const theme = useTheme();
  const currentMission = useSelector((state) => state.activity.currentMission);
  const { enqueueSnackbar } = useSnackbar();
  const { search } = useLocation();
  const billId = new URLSearchParams(search).get('bill_id');

  const { data } = useFetchHistorizedBillInfosQuery({
    variables: { billId: billId ?? '' },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const billingClientHistory = data?.historizedBillInfos?.billingClientHistory;

  return (
    <Stack direction="column" sx={{ px: 2 }} spacing={2}>
      <Typography
        variant="h3Bold"
        component="h3"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        Informations mission
        <IconButton
          onClick={() => {
            setIsModalContentLoading(true);
            setMissionModalStep(0);
            setMissionModalVisibility(true);
          }}
          sx={{
            backgroundColor: theme.palette.primary.darker,
          }}
          size="small"
        >
          <ModeEditIcon
            sx={{
              color: theme.palette.contrastText.main,
            }}
          />
        </IconButton>
      </Typography>
      <Stack direction="column" justifyContent="space-between" spacing={3}>
        <FieldDisplay
          title="Nom de mission"
          value={currentMission.name}
          flexDirection="column"
          noWrap={false}
          showColon={false}
          titleVariant="bodyBold"
          valueVariant="bodySemiBold"
        />
        <FieldDisplay
          title="Client à facturer"
          value={billingClientHistory?.clientCorporateName}
          flexDirection="column"
          noWrap={false}
          showColon={false}
          titleVariant="bodyBold"
          valueVariant="bodySemiBold"
        />
        {currentMission.billingInformation?.refMarket && (
          <FieldDisplay
            title="Référence"
            value={currentMission.billingInformation?.refMarket}
            flexDirection="column"
            noWrap={false}
            showColon={false}
            titleVariant="bodyBold"
            valueVariant="bodySemiBold"
          />
        )}
        <FieldDisplay
          title="Directeur mission"
          value={displayEmployee(
            currentMission.director?.firstName,
            currentMission.director?.lastName,
            false
          )}
          flexDirection="column"
          noWrap={false}
          showColon={false}
          titleVariant="bodyBold"
          valueVariant="bodySemiBold"
        />
      </Stack>
    </Stack>
  );
}

import { ApolloQueryResult } from '@apollo/client';
import { Delete } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import AvatarNameAndOccupationStack from 'components/commons/AvatarNameAndOccupationStack/AvatarNameAndOccupationStack';
import PolyAlert from 'components/commons/PolyAlert';
import PongoButton from 'components/MUIOverload/PongoButton';
import { useUserInfo } from 'components/User/UserProvider';
import {
  ActivityNode,
  AllAssignmentsForActivityForAdminQuery,
  AssignmentNode,
  EmployeeNode,
  EmployeeQuery,
  Exact,
  Scalars,
  useDeleteAssignmentMutation,
  useUpdateOrCreateAssignmentMutation,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import { requiredForm } from 'pages/MissionFollowUp/formValidators';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { getMinDate } from 'utils';

import ActivitySearchBar from './ActivitySearchBar';
import AssignmentDateRangePicker from './AssignmentDateRangePicker';
import EmployeeSearchBar from './EmployeeSearchBar';

type AlwaysRequiredProps = {
  isOpen: boolean;
  assignment: AssignmentNode | undefined;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setAssignment: React.Dispatch<
    React.SetStateAction<AssignmentNode | undefined>
  >;
  refetchAllAssignments?:
    | ((
        variables?:
          | Partial<
              Exact<{
                activityId: Scalars['ID'];
              }>
            >
          | undefined
      ) => Promise<ApolloQueryResult<AllAssignmentsForActivityForAdminQuery>>)
    | ((
        variables?:
          | Partial<
              Exact<{
                employeeId: Scalars['ID'];
              }>
            >
          | undefined
      ) => Promise<ApolloQueryResult<EmployeeQuery>>);
};

type OneOfTwoProps =
  | { activity: ActivityNode; employee?: never }
  | { activity?: never; employee: EmployeeNode };

type StaffingModalProps = AlwaysRequiredProps & OneOfTwoProps;

export default function StaffingModal({
  isOpen,
  activity,
  employee,
  assignment,
  setIsOpen,
  setAssignment,
  refetchAllAssignments,
}: StaffingModalProps) {
  const theme = useTheme();
  const [selectedActivity, setSelectedActivity] = useState<
    ActivityNode | undefined
  >(activity);
  const [selectedEmployee, setSelectedEmployee] = useState<
    EmployeeNode | undefined
  >(employee);

  const form = useForm({
    defaultValues: {
      dateRange: [new Date(), new Date().setDate(new Date().getDate() + 1)],
      staffingRate: assignment ? assignment.staffingRate : 100,
    },
  });

  const { employee: currentUser, refetch: refetchUserInfo } = useUserInfo();

  const [updateOrCreateAssignment, { loading: updateOrCreateIsLoading }] =
    useUpdateOrCreateAssignmentMutation({
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
      onCompleted: () => {
        refetchAllAssignments && refetchAllAssignments();
        enqueueSnackbar('Affectation sauvegardée', {
          variant: 'success',
        });
        onClose();

        // We are refetching UserInfo to update user's own assignments stored in the React context. This resolves the issue where,
        // after creating (or updating) an assignment for oneself and navigating to the 'Ma déclaration' page, a manual refresh
        // was required to see the newly created (or updated) assignment.
        if (currentUser?.id === selectedEmployee?.id) {
          refetchUserInfo();
        }
      },
    });

  const [deleteAssignment, { loading: deleteIsLoading }] =
    useDeleteAssignmentMutation({
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
      onCompleted: () => {
        refetchAllAssignments && refetchAllAssignments();
        enqueueSnackbar('Affectation supprimée', {
          variant: 'success',
        });
        onClose();

        // We are refetching UserInfo to update user's own assignments stored in the React context.
        // After deleting an assignment for oneself and navigating to the 'Ma déclaration' page,
        // the assignment is automatically removed
        if (currentUser?.id === selectedEmployee?.id) {
          refetchUserInfo();
        }
      },
    });

  const onClose = () => {
    setIsOpen(false);
    setAssignment(undefined);
    setSelectedActivity(undefined);
    setSelectedEmployee(undefined);
  };

  const handleSave = () => {
    const formValues = form.getValues();
    if (selectedEmployee && selectedActivity) {
      updateOrCreateAssignment({
        variables: {
          assignmentId: assignment?.id,
          activityId: selectedActivity.id,
          employeeId: selectedEmployee.id,
          beginningDate: moment(formValues.dateRange[0]).format('YYYY-MM-DD'),
          expirationDate: moment(formValues.dateRange[1]).format('YYYY-MM-DD'),
          staffingRate: Number(formValues.staffingRate),
        },
      });
    }
  };

  const handleDelete = () => {
    if (assignment) {
      deleteAssignment({
        variables: {
          assignmentId: assignment.id,
        },
      });
    }
  };

  useEffect(() => {
    if (activity && !selectedActivity) {
      setSelectedActivity(activity);
    }
    if (employee && !selectedEmployee) {
      setSelectedEmployee(employee);
    }
    if (assignment) {
      form.setValue('dateRange', [
        assignment.beginningDate,
        assignment.expirationDate,
      ]);
      form.setValue('staffingRate', assignment.staffingRate ?? 0);
      if (!selectedActivity) {
        setSelectedActivity(assignment.activity ?? activity);
      }
      if (!selectedEmployee) {
        setSelectedEmployee(assignment.employee ?? employee);
      }
    } else {
      if (
        selectedActivity &&
        selectedEmployee &&
        moment(selectedEmployee.hiringDate).isAfter(moment.now())
      ) {
        const maxExpirationDate = getMinDate(
          selectedEmployee.leavingDate,
          selectedActivity.expirationDate
        );
        if (maxExpirationDate) {
          form.setValue('dateRange', [
            selectedEmployee.hiringDate,
            maxExpirationDate,
          ]);
        } else {
          form.setValue('dateRange', [
            selectedEmployee.hiringDate,
            new Date(selectedEmployee.hiringDate).setDate(
              new Date(selectedEmployee.hiringDate).getDate() + 1
            ),
          ]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignment, activity, selectedActivity, employee, selectedEmployee]);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={false}>
      <DialogContent
        sx={{
          overflowX: 'hidden',
          width: '500px',
          backgroundColor: theme.palette.modalBackground.main,
          pt: 0,
        }}
        data-testid={'staffing-modal'}
      >
        <Stack
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Stack flexDirection={'row'} alignItems={'center'} sx={{ py: 2 }}>
            <Typography color="text.secondary" variant="h3Bold" sx={{ mr: 2 }}>
              {assignment
                ? 'Modifier une affectation'
                : 'Ajouter une affectation'}
            </Typography>
          </Stack>
          <IconButton edge={'end'} onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </Stack>
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            p: 1.5,
            borderRadius: '8px',
          }}
        >
          <PolyAlert severity="info" variant="outlined" sx={{ mb: 3 }}>
            Un collaborateur affecté à une mission peut déclarer du temps sur
            celle-ci.
          </PolyAlert>
          <FormProvider {...form}>
            <Stack height={employee ? 298 : 316} spacing={3}>
              <ActivitySearchBar
                value={selectedActivity}
                setActivity={setSelectedActivity}
                disabled={!!assignment || !!activity}
              />
              {selectedEmployee ? (
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <AvatarNameAndOccupationStack
                    isClickable={!employee}
                    employee={selectedEmployee}
                  />
                  {!assignment && !employee && (
                    <IconButton
                      size="medium"
                      color="primary"
                      onClick={() => setSelectedEmployee(undefined)}
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                </Stack>
              ) : (
                <EmployeeSearchBar
                  value={selectedEmployee}
                  setEmployee={setSelectedEmployee}
                />
              )}
              {selectedActivity && selectedEmployee && (
                <>
                  <Controller
                    required
                    name="dateRange"
                    control={form.control}
                    rules={{
                      required: requiredForm,
                    }}
                    render={({ onChange, value }) => (
                      <AssignmentDateRangePicker
                        value={value}
                        onChange={onChange}
                        onError={() =>
                          form.setError('dateRange', {
                            type: 'dateRangeError',
                          })
                        }
                        onClearError={() => form.clearErrors('dateRange')}
                        activity={selectedActivity}
                        employee={selectedEmployee}
                      />
                    )}
                  />

                  <Controller
                    required
                    name="staffingRate"
                    control={form.control}
                    rules={{
                      required: requiredForm,
                      min: 1,
                      max: 100,
                    }}
                    render={({ onChange, value }) => (
                      <FormControl required>
                        <InputLabel htmlFor="staffing-rate-input">
                          Taux de staffing (%)
                        </InputLabel>
                        <OutlinedInput
                          required
                          id="staffing-rate-input"
                          size="small"
                          type="number"
                          label="Taux de staffing (%)"
                          error={!isStaffingRateValid(Number(value))}
                          endAdornment={
                            <InputAdornment position="end">%</InputAdornment>
                          }
                          value={value}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (!isStaffingRateValid(Number(value))) {
                              form.setError('staffingRate', {
                                type: 'staffingRateError',
                              });
                            } else {
                              form.clearErrors('staffingRate');
                            }
                            onChange(e);
                          }}
                          inputProps={{
                            min: 0,
                            max: 100,
                          }}
                        />
                      </FormControl>
                    )}
                  />
                </>
              )}
              <Stack
                flexDirection="row"
                justifyContent="flex-end"
                display="flex"
                sx={{
                  display:
                    selectedActivity && selectedEmployee ? 'inherit' : 'none',
                }}
              >
                {!!assignment && (
                  <PongoButton
                    variant="text"
                    color="error"
                    onClick={handleDelete}
                    disabled={deleteIsLoading || updateOrCreateIsLoading}
                    sx={{
                      mr: 'auto',
                    }}
                    startIcon={<Delete />}
                  >
                    Supprimer
                  </PongoButton>
                )}
                <PongoButton
                  variant="contained"
                  buttonStyle="secondary"
                  disabled={deleteIsLoading || updateOrCreateIsLoading}
                  onClick={onClose}
                  sx={{ mr: 1 }}
                >
                  Annuler
                </PongoButton>
                <PongoButton
                  variant="contained"
                  buttonStyle="primary"
                  disabled={
                    !_.isEmpty(form.formState.errors) ||
                    !isStaffingRateValid(form.getValues()['staffingRate']) ||
                    updateOrCreateIsLoading ||
                    deleteIsLoading
                  }
                  onClick={handleSave}
                >
                  Enregistrer
                </PongoButton>
              </Stack>
            </Stack>
          </FormProvider>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

const isStaffingRateValid = (staffingRate: number | undefined): boolean => {
  if (staffingRate === undefined) {
    return false;
  }
  return 1 <= staffingRate && staffingRate <= 100;
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActivityNode, BillingClientNode } from 'generated/graphql';

const slice = createSlice({
  name: 'activity',
  initialState: {
    billingClients: [] as BillingClientNode[],
    activities: [] as ActivityNode[],
    currentMission: {} as ActivityNode,
    selectedMissions: [] as ActivityNode[],
    ownCurrentMissions: [] as ActivityNode[],
    ownPastMissions: [] as ActivityNode[],
    otherCurrentMissions: [] as ActivityNode[],
    otherPastMissions: [] as ActivityNode[],
    editMissionModalVisibility: false as Boolean,
  },
  reducers: {
    setActivities: (state, action: PayloadAction<ActivityNode[]>) => {
      state.activities = action.payload;
    },
    setCurrentMission: (state, action: PayloadAction<ActivityNode>) => {
      state.currentMission = action.payload;
    },
    setClients: (state, action: PayloadAction<BillingClientNode[]>) => {
      state.billingClients = action.payload;
    },
    setSelectedMission: (state, action: PayloadAction<ActivityNode[]>) => {
      state.selectedMissions = action.payload;
    },
    setOwnCurrentMissions: (state, action: PayloadAction<ActivityNode[]>) => {
      state.ownCurrentMissions = action.payload;
    },
    setOwnPastMissions: (state, action: PayloadAction<ActivityNode[]>) => {
      state.ownPastMissions = action.payload;
    },
    setOtherCurrentMissions: (state, action: PayloadAction<ActivityNode[]>) => {
      state.otherCurrentMissions = action.payload;
    },
    setOtherPastMissions: (state, action: PayloadAction<ActivityNode[]>) => {
      state.otherPastMissions = action.payload;
    },
    setEditMissionModalVisibility: (state, action: PayloadAction<Boolean>) => {
      state.editMissionModalVisibility = action.payload;
    },
  },
});

export default slice.reducer;
export const {
  setActivities,
  setCurrentMission,
  setClients,
  setSelectedMission,
  setOwnCurrentMissions,
  setOwnPastMissions,
  setOtherCurrentMissions,
  setOtherPastMissions,
  setEditMissionModalVisibility,
} = slice.actions;

import Box from '@mui/material/Box';
import { LineChart } from '@mui/x-charts';
import LoadingPlaceholder from 'components/commons/LoadingPlaceholder';
import Section from 'components/commons/Section';
import SectionTitle from 'components/commons/SectionTitle';
import { useAdrGraphForActivityQuery } from 'generated/graphql';
import moment from 'moment';
import React from 'react';

const ActivityAdrGraph = ({ activityId }: { activityId: string }) => {
  const { data, loading } = useAdrGraphForActivityQuery({
    variables: { activityId },
  });

  return (
    <Section>
      <SectionTitle>TJM vendu / coût</SectionTitle>
      <Box sx={{ width: '100%', height: 400, alignContent: 'center' }}>
        {loading ? (
          <LoadingPlaceholder />
        ) : (
          <LineChart
            margin={{ top: 10, bottom: 80 }}
            series={[
              {
                dataKey: 'adrSold',
                label: 'TJM Vendu',
                valueFormatter: formatAdr,
              },
              {
                dataKey: 'adrCost',
                label: 'TJM Coût',
                valueFormatter: formatAdr,
              },
            ]}
            xAxis={[
              {
                dataKey: 'month',
                scaleType: 'point',
                valueFormatter: (month) => moment(month).format('MMM[\n]YYYY'),
              },
            ]}
            yAxis={[{ valueFormatter: (value) => `${value} €` }]}
            dataset={data?.adrGraphForActivity}
            slotProps={{
              legend: {
                position: { vertical: 'bottom', horizontal: 'middle' },
              },
            }}
          />
        )}
      </Box>
    </Section>
  );
};

const formatAdr = (adr: number) => `${adr.toFixed(2)} €`;

export default ActivityAdrGraph;

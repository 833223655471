import MissionTypeIcon from 'components/commons/MissionTypeIcon';
import PolyModal from 'components/MUIOverload/PolyDialog/PolyModal';
import {
  ActivitiesActivityBillingTypeChoices,
  ActivitiesActivityTypeChoices,
  ActivityNode,
} from 'generated/graphql';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import urljoin from 'url-join';

import { ExternalActivityForm } from './ExternalActivityForm';
import { UninitializedExternalActivityForm } from './UninitializedExternalActivityForm';

type RequiredProps = {
  open: boolean;
  onClose: () => void;
};

type OptionalProps =
  | {
      activity: ActivityNode;
      refetchActivityInfo: () => void;
      billingType?: never;
    }
  | {
      billingType: ActivitiesActivityBillingTypeChoices;
      activity?: never;
      refetchActivityInfo?: never;
    };

type ExternalActivityModalProps = RequiredProps & OptionalProps;

export const ExternalActivityModal = ({
  open,
  activity,
  billingType,
  onClose,
  refetchActivityInfo,
}: ExternalActivityModalProps): JSX.Element => {
  const { path } = useRouteMatch();
  const history = useHistory();

  const isEdit = !!activity;
  billingType = isEdit ? activity.billingType : billingType;
  const isTM = billingType === ActivitiesActivityBillingTypeChoices.Tm;
  const billingTypeString = isTM ? 'régie' : 'forfait';
  const modalTitle = isEdit
    ? activity.name
    : `Création d'une nouvelle mission ${billingTypeString}`;

  const handleCreateCompleted = (createdActivity?: ActivityNode) => {
    createdActivity &&
      history.push(urljoin(path, createdActivity.id as string));
  };

  const handleEditCompleted = () => {
    refetchActivityInfo && refetchActivityInfo();
    onClose();
  };

  const handleCompleted = (createdActivity?: ActivityNode) => {
    isEdit ? handleEditCompleted() : handleCreateCompleted(createdActivity);
  };

  return (
    <PolyModal
      open={open}
      onClose={onClose}
      polyDialogTitle={modalTitle}
      polyDialogIcon={
        <MissionTypeIcon
          type={ActivitiesActivityTypeChoices.Ext}
          isTmContracts={isTM}
          tooltip
        />
      }
      maxWidth="md"
      noSecondaryButton
    >
      {isEdit ? (
        <ExternalActivityForm
          activity={activity}
          handleCompleted={handleCompleted}
          onClose={onClose}
        />
      ) : (
        <UninitializedExternalActivityForm
          billingType={billingType}
          handleCompleted={handleCompleted}
          onClose={onClose}
        />
      )}
    </PolyModal>
  );
};

import Box from '@mui/material/Box';
import React from 'react';

import ActivityAdrGraph from './ActivityAdrGraph';
import ActivityRevenueGraph from './ActivityRevenueGraph';

const FinancialReport = ({ activityId }: { activityId: string }) => (
  <Box display="flex" flexDirection="column" gap={3} my={3}>
    <ActivityRevenueGraph activityId={activityId} />
    <ActivityAdrGraph activityId={activityId} />
  </Box>
);

export default FinancialReport;

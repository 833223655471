import Box from '@mui/material/Box';
import { BarChart } from '@mui/x-charts';
import LoadingPlaceholder from 'components/commons/LoadingPlaceholder';
import Section from 'components/commons/Section';
import SectionTitle from 'components/commons/SectionTitle';
import {
  BillingPurchaseOrderCurrencyChoices,
  useRevenueGraphForActivityQuery,
} from 'generated/graphql';
import moment from 'moment';
import { totalFormat } from 'pages/ActivityPage/utils';
import React from 'react';

const formatRevenue = (value: number) =>
  totalFormat(value, BillingPurchaseOrderCurrencyChoices.Eur, true);

const ActivityRevenueGraph = ({ activityId }: { activityId: string }) => {
  const { data, loading } = useRevenueGraphForActivityQuery({
    variables: { activityId },
  });

  if (
    data?.revenueGraphForActivity?.every(
      ({ cumulatedEstimation, cumulatedRevenue }) =>
        cumulatedEstimation === 0 && cumulatedRevenue === 0
    )
  ) {
    return null;
  }

  return (
    <Section>
      <SectionTitle>Chiffre d’affaires</SectionTitle>
      <Box sx={{ width: '100%', height: 400, alignContent: 'center' }}>
        {loading ? (
          <LoadingPlaceholder />
        ) : (
          <BarChart
            margin={{ top: 10, bottom: 80, left: 65 }}
            dataset={data?.revenueGraphForActivity}
            series={[
              {
                dataKey: 'cumulatedEstimation',
                label: 'CA prévisionnel',
                valueFormatter: formatRevenue,
                stack: 'total',
              },
              {
                dataKey: 'cumulatedRevenue',
                label: 'CA réalisé',
                valueFormatter: formatRevenue,
              },
            ]}
            xAxis={[
              {
                dataKey: 'month',
                scaleType: 'band',
                valueFormatter: (month) => moment(month).format('MMM[\n]YYYY'),
              },
            ]}
            yAxis={[
              {
                valueFormatter: (value) => `${value / 1000} K€`,
              },
            ]}
            slotProps={{
              legend: {
                position: { vertical: 'bottom', horizontal: 'middle' },
                padding: 0,
              },
            }}
          />
        )}
      </Box>
    </Section>
  );
};

export default ActivityRevenueGraph;
